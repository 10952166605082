import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LookupService } from '../../../shared/services/lookup/lookup.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { ReportShipmentService } from '../../services/report-shipment.service';
import { IReportShipmentUS } from './models/report-shipment-us.interface';
import { ShipmentType } from '../../../shared/enum/general-enum';
import { ICustomerCarrier } from '../../../shared/models/customer-carrier.interface';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { UserTermsConditionsComponent } from '../../../account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { IReportService } from '../../models/report-service.interface';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-report-shipment-us-dialog',
  templateUrl: './report-shipment-us-dialog.component.html',
  styleUrls: ['./report-shipment-us-dialog.component.scss'],
})
export class ReportShipmentUsDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup;

  // TODO: get carriers from API.
  public carriers: ICustomerCarrier[];
  public services: IReportService[];
  public filteredServices: IReportService[];

  public isCarriersLoading = false;
  public isServiceTypesLoading = false;
  public maxInsuredValue = 0;
  private selectedCarrierCode: string;
  private getReportServiceSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationService,
              private lookupService: LookupService,
              private utilityService: UtilityService,
              private validationService: ValidationService,
              private reportShipmentService: ReportShipmentService,
              private errorHandlerService: ErrorHandlerService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<ReportShipmentUsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
            private translate: TranslateService,) {
    this.loadInitialValues();
  }

  public ngOnInit() {
    this.formGroup = this.formBuilder.group({
      carrier: ['', Validators.compose([Validators.required])],
      serviceType: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      coverage: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(1000000000)])],
      trackingNumber: ['', Validators.compose([Validators.required])],
      referenceNumber: [''],
      zipCode: [''],
      isInsuranceOnly: [false, Validators.compose([Validators.required])],
      isAgreeToTerms: [false],

      userId: [this.data.user.UserId],
      customerId: [this.data.customer.CustomerId],
    });

    this.monitorValueChanges();
  }

  private loadInitialValues() {
    this.isCarriersLoading = true;
    this.lookupService.getCustomerCarriers(this.data.customer.CustomerId)
      .subscribe(
        carriers => this.handleGetCustomerCarriersSuccess(carriers),
        err => this.handleGetCustomerCarriersFailure(err),
      );
  }

  private handleGetCustomerCarriersSuccess(carriers) {
    this.carriers = carriers;
    this.utilityService.delay(() => {
      this.isCarriersLoading = false;
    });
  }

  private handleGetCustomerCarriersFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting customer carriers',
      NotificationType.ERROR);
    this.utilityService.delay(() => {
      this.isCarriersLoading = false;
    });
  }

  private monitorValueChanges() {
    this.formGroup.controls.carrier.valueChanges
      .subscribe(
        
        carrierCode => this.handleCarrierChanged(carrierCode),
        
      );

    this.formGroup.controls.serviceType.valueChanges
      .subscribe(
        (serviceTypeCode) => {
          if (!serviceTypeCode) {
            return;
          }

          const selectedService = this.services.find(item => item.ServiceCode === serviceTypeCode && item.CarrierCode === this.selectedCarrierCode);
          if (!selectedService) {
            return;
          }

          this.setMaxInsuredValue(selectedService.ReportMaxCoverage);
        },
      );
  }

  private handleCarrierChanged(carrierCode: string) {
    this.selectedCarrierCode = carrierCode;
    this.isServiceTypesLoading = true;
    // this.lookupService.getCarrierServices(carrierCode)
    //     .subscribe(
    //         services => this.handleGetCarrierServicesSuccess(services),
    //         err => this.handleGetCarrierServicesFailure(err),
    //     );

    if (this.services) {
      this.handleGetReportServicesSuccess(this.services);
    } else {
      this.utilityService.clearSubscriptions([this.getReportServiceSubscription]);
      this.getReportServiceSubscription = this.reportShipmentService.getReportServices()
        .subscribe(
          services => this.handleGetReportServicesSuccess(services),
          err => this.handleGetReportServicesFailure(err),
        );
    }
  }

  private handleGetReportServicesSuccess(services: IReportService[]) {
    this.services = services;
    this.filteredServices = _.cloneDeep(this.services).filter(
      (service) => {
        return service.IsAllowed && this.formGroup.controls.carrier.value.toString().toUpperCase() === service.CarrierCode.toUpperCase();
      },
    );

    this.formGroup.controls.serviceType.reset();
    this.formGroup.controls.serviceType.enable();
    this.utilityService.delay(() => {
      this.isServiceTypesLoading = false;
    });
  }

  private handleGetReportServicesFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed getting carrier services',
      NotificationType.ERROR);
    this.utilityService.delay(() => {
      this.isServiceTypesLoading = false;
    });
  }

  public onFormSubmit(event, form) {
    event.preventDefault();

    const reportShipment: IReportShipmentUS = {
      Carrier: form.carrier,
      Service: form.serviceType,
      Coverage: form.coverage,
      ShipmentType: form.isInsuranceOnly ? ShipmentType.InsuranceOnly : ShipmentType.ShipandInsure,
      TrackingNumber: form.trackingNumber.replace(/\s/g, ''),
      Reference: form.referenceNumber,
      ZipCode: form.zipCode,
      UserId: form.userId,
      CustomerId: form.customerId,
    };

    const reportShipments = [reportShipment];

    this.reportShipmentService.saveReportShipmentUS(reportShipments)
      .subscribe(
        result => this.handleSaveReportShipmentUSSuccess(result),
        err => this.handleSaveReportShipmentUSFailure(err),
      );
  }

  private handleSaveReportShipmentUSSuccess(result) {
    this.notificationService.notify(
      this.translate.instant('reportShipments.shipmentReportedSuccess'),
      this.translate.instant('reportShipments.success'),
      NotificationType.SUCCESS);
    this.dialogRef.close(true);
  }

  private handleSaveReportShipmentUSFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed saving report shipment',
      NotificationType.ERROR);
  }

  public isFormValid(): boolean {
    return this.formGroup.valid && this.formGroup.controls.isAgreeToTerms.value;
  }

  public openTermsAndConditionsDialog(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {},
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    let dialogRef: MatDialogRef<UserTermsConditionsComponent>;
    dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  private setMaxInsuredValue(value: number) {
    this.maxInsuredValue = value;
    const control = this.formGroup.controls.coverage;

    if (value <= 0) {
      this.validationService.setFormControlValidators(
        control,
        Validators.compose([Validators.required, Validators.min(1)]));

      return;
    }

    this.validationService.setFormControlValidators(
      control,
      Validators.compose([Validators.required, Validators.min(1), Validators.max(value)]));
  }
}
